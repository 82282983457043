$(document).ready(function () {
    let hamburger = $('.hamburger-icon'),
        showMenu = $('.main-nav');
    showMenuL = $('.main-nav__list');
    showMenuS = $('.social-nav__list');
    hamburger.click(function() {
        hamburger.toggleClass('active');
        showMenu.toggleClass('show fadeInRight animated');
        showMenuL.toggleClass('show');
        showMenuS.toggleClass('show');

        return false;
    });
    showMenu.on("click", "li", function() {
        if(showMenu.hasClass('show')) {
            showMenu.toggleClass('show');
            showMenuL.toggleClass('show');
            showMenuS.toggleClass('show');
            hamburger.toggleClass('active');
        }
    });


    $('.suggestion_title').click(function () {
        $(this).toggleClass('active');
        $(this).siblings('.suggestion_info__wrapper').slideToggle();
    })
});